import React, { Component } from 'react'
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
const menus = [
    {
        menu1: "Disclosure Under Regulation 46",
        menu2: [
            // {
            //     name: "Details of Business",
            //     menu3: [],
            //     years: []
            // },
            {
                name: "Policies",
                menu3: [],
                years: [
                    {
                        name: "AVL - Dividend Distribution Policy",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/AVL_Dividend_Distribution_Policy.pdf"
                    },
                    {
                        name: "AVL - Environmental, Social and Governance Policy",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/AVL_Environmental_Social_and_Governance_Policy.pdf"
                    },
                    {
                        name: "AVL - Risk Management Policy",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/AVL_Risk_Management_Policy.pdf"
                    },
                    {
                        name: "Policy on Code of Conduct fo Regulating, Monitoring and Reporting",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Code of Conduct fo Regulating, Monitoring and Reporting.pdf"
                    },
                    {
                        name: "Policy on Code of Conduct for Board and Senior Management",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Code of Conduct for Board and Senior Management.pdf"
                    },
                    {
                        name: "Policy on Code of Practices and Procedures for Fair Disclosures",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Code of Practices and Procedures for Fair Disclosures.pdf"
                    },
                    {
                        name: "Policy on Corporate Social Responsibility",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Corporate Social Responsibility.pdf"
                    },
                    {
                        name: "Policy on Determination of the Materiality Events",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Determination of the Materiality Events.pdf"
                    },
                    {
                        name: "Policy on Nomination and Remuneration",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Nomination and Remuneration.pdf"
                    },
                    {
                        name: "Policy on Preservation of Documents",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Preservation of Documents.pdf"
                    },
                    {
                        name: "Policy on Related Party Transactions",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Related Party Transactions.pdf"
                    },
                    {
                        name: "Policy on Whistle Blower",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/2. Policies/Policy on Whistle Blower.PDF"
                    }
                ]
            },
            {
                name: "Stock Exchange Intimations - Reg.30",
                menu3: [
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "Change-in-Director-BSE",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/Change-in-Director-BSE.pdf"
                            },
                            {
                                name: "closure_of_trading_window_28_09_2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/closure_of_trading_window_28_09_2020.pdf"
                            },
                            {
                                name: "closure_of_trading_window_31_12_2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/closure_of_trading_window_31_12_2020.pdf"
                            },
                            {
                                name: "impact of Covid 19 31-07-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/impact of Covid 19 31-07-2020.pdf"
                            },
                            {
                                name: "outcome-of-BM-05-03-21",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/outcome-of-BM-05-03-21.pdf"
                            },
                            {
                                name: "outcome-of-BM-09-05-20",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/outcome-of-BM-09-05-20.pdf"
                            },
                            {
                                name: "outcome-of-BM-22-01-21",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/outcome-of-BM-22-01-21.pdf"
                            },
                            {
                                name: "outcome-of-BM-27-07-20",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/outcome-of-BM-27-07-20.pdf"
                            },
                            {
                                name: "Regulations_30_on_06_11_2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/Regulations_30_on_06_11_2020.pdf"
                            },
                            {
                                name: "Regulations_30_on_18_09_2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/Regulations_30_on_18_09_2020.pdf"
                            },
                            {
                                name: "Regulations_30_on_28_08_2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/Regulations_30_on_28_08_2020.pdf"
                            },
                            {
                                name: "Resignation-of-Director 22.01.2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2020-21/Resignation-of-Director 22.01.2021.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "closure_of_trading_window_31_03_2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/closure_of_trading_window_31_03_2021.pdf"
                            },
                            {
                                name: "Outcome of board meeting 24 04 21",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/Outcome of board meeting 24 04 21.pdf"
                            },
                            {
                                name: "outcome-of-BM-15-07-21",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/outcome-of-BM-15-07-21.pdf"
                            },
                            {
                                name: "outcome-of-BM-21-10-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/outcome-of-BM-21-10-2020.pdf"
                            },
                            {
                                name: "outcome-of-BM-24-04-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/outcome-of-BM-24-04-2021.pdf"
                            },
                            {
                                name: "outcome-of-BM-27-01-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/outcome-of-BM-27-01-2022.pdf"
                            },
                            {
                                name: "outcome-of-BM-28-10-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2021-22/outcome-of-BM-28-10-2021.pdf"
                            }
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "bse_intimation_rg_30",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/bse_intimation_rg_30.pdf"
                            },
                            {
                                name: "company_secretary_appointment_2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/company_secretary_appointment_2022.pdf"
                            },
                            {
                                name: "outcome-of-BM-06-05-22",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/outcome-of-BM-06-05-22.pdf"
                            },
                            {
                                name: "outcome-of-BM-21-10-22",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/outcome-of-BM-21-10-22.pdf"
                            },
                            {
                                name: "outcome-of-BM-29-07-22",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/outcome-of-BM-29-07-22.pdf"
                            },
                            {
                                name: "Clarification to BSE 01.11.2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/Clarification to BSE 01.11.2022.pdf"
                            },
                            {
                                name: "TRADING WINDOW CLOSURE 31.12.2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/TRADING WINDOW CLOSURE 31.12.2022.pdf"
                            },
                            {
                                name: "DISCLOSURE-REG-30 ADDITONAL UNIT 23.01.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/DISCLOSURE-REG-30 ADDITONAL UNIT 23.01.2023.pdf"
                            },
                            {
                                name: "OUTCOME-OF-BM 23.01.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/OUTCOME-OF-BM 23.01.2023.pdf"
                            },
                            {
                                name: "RECEIPT OF WORK ORDER 10.03.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/Receipt-of-work-order-10-03-23.pdf"
                            },
                            {
                                name: "SE Intimation - Closure of Trading Window",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/SE Intimation - Closure of Trading Window.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 13.04.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2022-23/outcome-of-bm-13-04-2023.pdf"
                            }
                            
                        ]
                    }, 
                    {
                        name: "2023-24",
                        years: [
                           
                            {
                                name: "Closure of Trading Window 30.03.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Closure_of_Trading_Window_30_03_2024.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 22.03.2024 - BDL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_PO_22_03_2024_BDL.pdf"
                            },
                            {
                                name: "DISCLOSURE REG.30 - APPOINTMENTS",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/DISCLOSURE_REG_30_APPOINTMENTS.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 02.03.2024 - L&T",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_PO_02_03_2024_L_T.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 02.03.2024 - MOV",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_PO_02_03_2024_MOV.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 29.02.2024 - GRSL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_PO_29_02_2024_GRSL.pdf"
                            },
                            {
                                name: "CLOSURE OF TRADING WINDOW 26.02.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/closure_of_trading_window_26_02_2024.pdf"
                            },
                            {
                                name: "RECEIPT OF SUPPLY ORDER 29.01.2024 - HSL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/receipt_of_supply_order_29_01_2024_hsl.pdf"
                            },
                            {
                                name: "Receipt of Supply Order 19.01.2024 - Navy",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/receipt_of_supply_order_19_01_2024_Navy.pdf"
                            },
                            {
                                name: "CLOSURE OF TRADING WINDOW 29.12.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/CLOSURE_OF_TRADING_WINDOW_29_12_2023.pdf"
                            },
                            {
                                name: "Resignation of Mr. EBV Ramana Gupta 26.12.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/resignation_of_Mr_EBV_Ramana_Gupta_26_12_2023.pdf"
                            },
                            {
                                name: "RECEIPT OF ORDER 03.12.2023 NSIL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT-OF-ORDER-03-12-2023-NSIL.pdf"
                            },
                            {
                                name: "Reg.30 Intimation - ESOP Grants Issue",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Reg-30-Intimation-ESOP-Grants-Issue.pdf"
                            },
                            
                            {
                                name: "REG.30 UPDATE ON BONUS",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/REG-30-UPDATE-ON-BONUS.pdf"
                            },
                            {
                                name: "Reg.30 Allotment of Bonus Shares",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Re-30-allotment-of-bonus-shares.pdf"
                            },
                            {
                                name: "Record Date Intimation 11.11.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Record-Date-Intimation-11-11-2023.pdf"
                            },
                            {
                                name: "Reg.30 Disclosure 11.11.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Reg-30-Disclosure-11-11-2023.pdf"
                            },
                            {
                                name: "RECEIPT OF ORDER 01.11.2023 - CSL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_ORDER_01_11_2023_CSL.pdf"
                            },
                            {
                                name: "RECEIPT OF ORDER 01.11.2023 - ISRO",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT_OF_ORDER_01_11_2023_ISRO.pdf"
                            },
                            {
                                name: "REG.30 BONUS & ESOP 09.10.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/reg_30_bonus_esop_09_10_2023.pdf"
                            },
                            {
                                name: "Receipt of order 06.10.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Receipt_of_order_06_10_2023.pdf"
                            },
                            {
                                name: "Closure of Trading Window 28.09.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/closure_of_trading_window_28_09_2023.pdf"
                            },
                            {
                                name: "Receipt of Order 13.09.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/receipt_of_order_13_09_2023.pdf"
                            },
                            {
                                name: "REG.30 26.08.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/REG_30_26_08_2023.pdf"
                            },
                            {
                                name: "Receipt of Order 23.08.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/receipt_of_order_23_08_2023.pdf"
                            },
                            {
                                name: "New ISIN Intimation 10.08.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/New_ISIN_Intimation_10_08_2023.pdf"
                            },
                            {
                                name: "REG.30 - AMENDMENT TO MOA",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/REG-30-AMENDMENT-TO-MOA.pdf"
                            },
                            {
                                name: "RECORD DATE INTIMATION 31.07.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECORD-DATE-INTIMATION-31-07-2023.pdf"
                            },
                            {
                                name: "RECEIPT OF ORDER 13.07.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/RECEIPT OF ORDER 13.07.2023.pdf"
                            },
                            {
                                name: "CLOSURE OF TRADING WINDOW 30.06.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/CLOSURE OF TRADING WINDOW 30.06.2023.pdf"
                            },
                           
                            {
                                name: "Disclosure Stock Split 23.06.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Reg.30 Disclosure_Stock_Split 23.06.2023.pdf"
                            },
                            {
                                name: "Closure of Trading Window 06.05.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/closure_of_trading_window_06_05_2023.pdf"
                            },
                            {
                                name: "Record Date Intimation 17.05.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Record Date Intimation 17.05.2023.pdf"
                            },
                            {
                                name: "Notice of AGM FY 2022-23",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Notice of AGM FY 2022-23.pdf"
                            },
                            {
                                name: "Closure of trading window 07.06.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/Closure-of-trading-window-07.06.2023.pdf"
                            }
                            ,
                            {
                                name: "REG. 30 RECEIPT OF ORDER",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2023-24/REG. 30 RECEIPT OF ORDER.pdf"
                            }
                            
                        ]
                    },
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "Order Intimation 31.03.2025 GSL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_31_03_2025_GSL.pdf"
                            },
                            {
                                name: "Intimation - Directors Appointment 27.03.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Intimation_Directors_Appointment_27_03_2025.pdf"
                            },
                            {
                                name: "Order Intimation 25.02.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_25_02_2025.pdf"
                            },
                            {
                                name: "Order Intimation 19.02.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_19_02_2025.pdf"
                            },
                            {
                                name: "Order Intimation 04.02.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_04_02_2025.pdf"
                            },
                            {
                                name: "Intimation - Rights Issue Withdrawal 18.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Intimation_Rights_Issue_Withdrawal_18_01_2025.pdf"
                            },
                            {
                                name: "Order Intimation 16.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_16_01_2025.pdf"
                            },
                            {
                                name: "Trading Window Closure 31.12.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Trading_Window_Closure_31_12_2024.pdf"
                            },
                            {
                                name: "ORDER INTIMATION 27.09.2024 - L&T",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/ORDER_INTIMATION_27_09_2024_L_T.pdf"
                            },
                            {
                                name: "ORDER INTIMATION 26.09.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/ORDER_INTIMATION_26_09_2024.pdf"
                            },
                            {
                                name: "CLOSURE OF TRADING WINDOW 21.09.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/CLOSURE_OF_TRADING_WINDOW_21_09_2024.pdf"
                            },
                            {
                                name: "Order Intimation 17.09.2024 NSIL",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Order_Intimation_17_09_2024_NSIL.pdf"
                            },
                            {
                                name: "Order Intimation 21.08.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/order_intimation_21_08_2024.pdf"
                            },
                            {
                                name: "NSE Listing Intimation 29.07.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/NSE_Listing_Intimation_29_07_2024.pdf"
                            },
                            {
                                name: "Donation of Shares to LF 29.07.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Donation_of_Shares_to_LF_29_07_2024.pdf"
                            },
                            {
                                name: "Business Responsibility & Sustainability Report FY 2023-24",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Business_Responsibility_Sustainability_Report_FY_2023_24.pdf"
                            },
                            {
                                name: "CLOSURE OF TRADING WINDOW 29.06.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/CLOSURE_OF_TRADING_WINDOW_29_06_2024.pdf"
                            },
                            {
                                name: "RECEIPT OF SO 29.05.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/RECEIPT_OF_SO_29_05_2024.pdf"
                            },
                            {
                                name: "CESSATION OF ID 30.05.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/CESSATION_OF_ID_30_05_2024.pdf"
                            },
                            {
                                name: "ESOP GRANT 28.05.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/ESOP_GRANT_28_05_2024.pdf"
                            },
                            {
                                name: "Notice of 34th AGM",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Notice_of_34th_AGM.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 07.05.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/RECEIPT_OF_PO_07_05_2024.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 06.05.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/RECEIPT_OF_PO_06_05_2024.pdf"
                            },
                            {
                                name: "RECORD DATE 25.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/RECORD_DATE_25_04_2024.pdf"
                            },
                            {
                                name: "CORPORATE ACTION 25.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/CORPORATE_ACTION_25_04_2024.pdf"
                            },
                            {
                                name: "ESOP GRANT 25.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/ESOP_GRANT_25_04_2024.pdf"
                            },
                            {
                                name: "Receipt of SO 20.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/Receipt_of_SO_20_04_2024.pdf"
                            },
                            {
                                name: "DONATION OF SHARES INTIMATION 05.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/DONATION_OF_SHARES_INTIMATION_05_04_2024.pdf"
                            },
                            {
                                name: "RECEIPT OF PO 01.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/3. Stock Exchange Intimations - Reg.30/2024-25/RECEIPT_OF_PO_01_04_2024.pdf"
                            }
                        ]
                    }
                ],
                years: []
            },
            {
                name: "Shareholding Pattern",
                menu3: [
                    {
                        name: "2011-12",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2011-12/AVL_SHP Q1 - 30-06-2011.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2011-12/AVL_SHP Q2 - 30-09-2011.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2011-12/AVL_SHP Q3 - 31-12-2011.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2011-12/AVL_SHP Q4 - 31-03-2012.pdf"
                            },
                        ]
                    },
                    {
                        name: "2012-13",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2012-13/AVL_SHP Q1 - 30-06-2012.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2012-13/AVL_SHP Q2 - 30-09-2012.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2012-13/AVL_SHP Q3 - 31-12-2012.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2012-13/AVL_SHP Q4 - 31-03-2013.pdf"
                            },
                        ]
                    },
                    {
                        name: "2013-14",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2013-14/AVL_SHP Q1 - 30-06-2013.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2013-14/AVL_SHP Q2 - 30-09-2013.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2013-14/AVL_SHP Q3 - 31-12-2013.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2013-14/AVL_SHP Q4 - 31-03-2014.pdf"
                            },
                        ]
                    },
                    {
                        name: "2014-15",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2014-15/AVL_SHP Q1 - 30-06-2014.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2014-15/AVL_SHP Q2 - 30-09-2014.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2014-15/AVL_SHP Q3 - 31-12-2014.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2014-15/AVL_SHP Q4 - 31-03-2015.pdf"
                            },
                        ]
                    },
                    {
                        name: "2015-16",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2015-16/AVL_SHP Q1 - 30-06-2015.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2015-16/AVL_SHP Q2 - 30-09-2015.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2015-16/AVL_SHP Q3 - 31-12-2015.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2016",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2015-16/AVL_SHP Q4 - 31-03-2016.pdf"
                            },
                        ]
                    },
                    {
                        name: "2016-17",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2016",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2016-17/AVL_SHP Q1 - 30-06-2016.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2016",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2016-17/AVL_SHP Q2 - 30-09-2016.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2016",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2016-17/AVL_SHP Q3 - 31-12-2016.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2017",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2016-17/AVL_SHP Q4 - 31-03-2017.pdf"
                            },
                        ]
                    },
                    {
                        name: "2017-18",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2017",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2017-18/AVL_SHP Q1 - 30-06-2017.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2017",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2017-18/AVL_SHP Q2 - 30-09-2017.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2017",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2017-18/AVL_SHP Q3 - 31-12-2017.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2017-18/AVL_SHP Q4 - 31-03-2018.pdf"
                            },
                        ]
                    },
                    {
                        name: "2018-19",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2018-19/AVL_SHP Q1 - 30-06-2018.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2018-19/AVL_SHP Q2 - 30-09-2018.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2018-19/AVL_SHP Q3 - 31-12-2018.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2018-19/AVL_SHP Q4 - 31-03-2019.pdf"
                            },
                        ]
                    },
                    {
                        name: "2019-20",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2019-20/AVL_SHP Q1 - 30-06-2019.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2019-20/AVL_SHP Q2 - 30-09-2019.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2019-20/AVL_SHP Q3 - 31-12-2019.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2019-20/AVL_SHP Q4 - 31-03-2020.pdf"
                            },
                        ]
                    },
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2020-21/AVL_SHP Q1 - 30-06-2020.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2020-21/AVL_SHP Q2 - 30-09-2020.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2020-21/AVL_SHP Q3 - 31-12-2020.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2020-21/AVL_SHP Q4 - 31-03-2021.pdf"
                            },
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2021-22/AVL_SHP Q1 - 30-06-2021.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2021-22/AVL_SHP Q2 - 30-09-2021.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2021-22/AVL_SHP Q3 - 31-12-2021.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2021-22/AVL_SHP Q4 - 31-03-2022.pdf"
                            },
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "AVL_SHP Q1 - 30-06-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2022-23/AVL_SHP Q1 - 30-06-2022.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30-09-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2022-23/AVL_SHP Q2 - 30-09-2022.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31-12-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2022-23/Shareholding Pattern Q3 2022-23.pdf"
                            },
                            {
                                name: "AVL_SHP Q4 - 31-03-2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2022-23/AVL-SHP-Q4-31-03-2023.pdf"
                            }
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "AVL_SHP Q4 - 31.03.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2023-24/AVL_SHP_Q4_31_03_2024.pdf"
                            },
                            {
                                name: "AVL_SHP Q3 - 31.12.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2023-24/AVL_SHP_Q2_31_12_2023.pdf"
                            },
                            {
                                name: "AVL_SHP Q2 - 30.09.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2023-24/AVL_SHP_Q2_30_09_2023.pdf"
                            },
                            {
                                name: "AVL_SHP Q1 - 30.06.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2023-24/AVL_SHP Q1 - 30.06.2023.pdf"
                            }
                        ]
                    },
                    
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "Shareholding Pattern 31.12.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2024-25/Shareholding_Pattern_31_12_2024.pdf"
                            },
                            {
                                name: "AVL_SHP 30.09.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2024-25/AVL_SHP_30_09_2024.pdf"
                            },
                            {
                                name: "AVL_SHP Q1 30.06.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/4. Shareholding Pattern/2024-25/AVL_SHP_Q1_30_06_2024.pdf"
                            }
                        ]
                    }
                ],
                years: []
            },
            {
                name: "Secretarial Compliance Report",
                menu3: [
                    {
                        name: "2018-19",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2018-19",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2018-19/Secretarial Compliance Report 2018-19.pdf"
                            }
                        ]
                    },
                    {
                        name: "2019-20",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2019-20",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2019-20/Secretarial Compliance Report 2019-20.pdf"
                            }
                        ]
                    },
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2020-21",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2020-21/Secretarial Compliance Report 2020-21.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2021-22",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2021-22/Secretarial Compliance Report 2021-22.pdf"
                            }
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2022-23",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2022-23/SECRETARIAL COMPLIANCE REPORT 2022-23.pdf"
                            }
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "Secretarial Compliance Report 2023-24",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/5. Secretarial Compliance Report/2023-24/SECRETARIAL_COMPLIANCE_REPORT_2023_24.pdf"
                            }
                        ]
                    }
                ],
                years: []
            },
            {
                name: "News paper Publications",
                menu3: [
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "agm_paper_add_2020_english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/agm_paper_add_2020_english.pdf"
                            },
                            {
                                name: "agm_paper_add_2020_telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/agm_paper_add_2020_telugu.pdf"
                            },
                            {
                                name: "iepf-paper-notice-english 03.07.2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/iepf-paper-notice-english 03.07.2020.pdf"
                            },
                            {
                                name: "iepf-paper-notice-telugu 03.07.2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/iepf-paper-notice-telugu 03.07.2020.pdf"
                            },
                            {
                                name: "paper-notice-17-01-2021-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-17-01-2021-telugu.pdf"
                            },
                            {
                                name: "Paper-Notice-22-10-20-English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/Paper-Notice-22-10-20-English.pdf"
                            },
                            {
                                name: "Paper-Notice-22-10-20-Telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/Paper-Notice-22-10-20-Telugu.pdf"
                            },
                            {
                                name: "paper-notice-27-07-20-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-27-07-20-english.pdf"
                            },
                            {
                                name: "paper-notice-27-07-20-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-27-07-20-telugu.pdf"
                            },
                            {
                                name: "paper-notice-english-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-english-2020.pdf"
                            },
                            {
                                name: "Paper-Notice-For-Board-Meeting-dated-15-10-20-English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/Paper-Notice-For-Board-Meeting-dated-15-10-20-English.pdf"
                            },
                            {
                                name: "Paper-Notice-For-Board-Meeting-dated-15-10-20-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/Paper-Notice-For-Board-Meeting-dated-15-10-20-telugu.pdf"
                            },
                            {
                                name: "paper-notice-q1-2020-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-q1-2020-english.pdf"
                            },
                            {
                                name: "paper-notice-q1-2020-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-q1-2020-telugu.pdf"
                            },
                            {
                                name: "paper-notice-telugu-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2020-21/paper-notice-telugu-2020.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "paper-notice-17-07-2021-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-17-07-2021-english.pdf"
                            },
                            {
                                name: "paper-notice-23-01-2021-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-23-01-2021-english.pdf"
                            },
                            {
                                name: "paper-notice-23-01-2021-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-23-01-2021-telugu.pdf"
                            },
                            {
                                name: "paper-notice-28-01-22-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-28-01-22-english.pdf"
                            },
                            {
                                name: "paper-notice-28-01-22-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-28-01-22-telugu.pdf"
                            },
                            {
                                name: "paper-notice-30-10-2021-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-30-10-2021-english.pdf"
                            },
                            {
                                name: "paper-notice-30-10-2021-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-30-10-2021-telugu.pdf"
                            },
                            {
                                name: "paper-notice-english-agm-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-english-agm-2021.pdf"
                            },
                            {
                                name: "Paper-Notice-for-Board-meeting-24-04-2021-English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/Paper-Notice-for-Board-meeting-24-04-2021-English.pdf"
                            },
                            {
                                name: "Paper-Notice-for-Board-meeting-24-04-2021-Telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/Paper-Notice-for-Board-meeting-24-04-2021-Telugu.pdf"
                            },
                            {
                                name: "Paper-Notice-for-Q4-and-Annual-Financials-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/Paper-Notice-for-Q4-and-Annual-Financials-2021.pdf"
                            },
                            {
                                name: "paper-notice-telugu-agm-2021 (2)",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-telugu-agm-2021 (2).pdf"
                            },
                            {
                                name: "paper-notice-telugu-agm-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2021-22/paper-notice-telugu-agm-2021.pdf"
                            },
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "iepf-paper-notice-english-02.11.2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/iepf-paper-notice-english-02.11.2022.pdf"
                            },
                            {
                                name: "iepf-paper-notice-telugu-02.11.2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/iepf-paper-notice-telugu-02.11.2022.pdf"
                            },
                            {
                                name: "paper-notice-07-05-22-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-07-05-22-english.pdf"
                            },
                            {
                                name: "paper-notice-07-05-22-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-07-05-22-telugu.pdf"
                            },
                            {
                                name: "paper-notice-22-10-2022-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-22-10-2022-english.pdf"
                            },
                            {
                                name: "paper-notice-22-10-2022-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-22-10-2022-telugu.pdf"
                            },
                            {
                                name: "paper-notice-30-07-2022-english",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-30-07-2022-english.pdf"
                            },
                            {
                                name: "paper-notice-30-07-2022-telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-30-07-2022-telugu.pdf"
                            },
                            {
                                name: "paper-notice-english-agm-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-english-agm-2022.pdf"
                            },
                            {
                                name: "paper-notice-telugu-agm-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-telugu-agm-2022.pdf"
                            },
                            {
                                name: "PAPER NOTICE-23.01.2023-ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/PAPER NOTICE-23.01.2023-ENGLISH.pdf"
                            },
                            {
                                name: "PAPER NOTICE-23.01.2023-TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/PAPER NOTICE-23.01.2023-TELUGU.pdf"
                            }
                            ,
                            {
                                name: "PAPER NOTICE-14.04.2023-English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-14-04-23-english.pdf"
                            }
                            ,
                            {
                                name: "PAPER NOTICE-14.04.2023-TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2022-23/paper-notice-14-04-23-telugu.pdf"
                            }
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "PAPER NOTICE Q4 25.04.2024 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_Q4_25_04_2024_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE Q4 25.04.2024 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_Q4_25_04_2024_ENGLISH.pdf"
                            },
                            {
                                name: "PAPER NOTICE-14.04.2023-English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/paper-notice-14-04-23-english.pdf"
                            },
                            {
                                name: "PAPER NOTICE-14.04.2023-TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/paper-notice-14-04-23-telugu.pdf"
                            },
                            {
                                name: "AGM Notice 31.05.2023_Telugu",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/AGM Notice 31.05.2023_Telugu.pdf"
                            },
                            {
                                name: "AGM Notice 31.05.2023_English",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/AGM Notice 31.05.2023_English.pdf"
                            },
                            {
                                name: "NOTICE OF POSTAL BALLOT_01.07.2023_ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/NOTICE OF POSTAL BALLOT_01.07.2023_ENGLISH.pdf"
                            },
                            {
                                name: "NOTICE OF POSTAL BALLOT_01.07.2023_TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/NOTICE OF POSTAL BALLOT_01.07.2023_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE 13.07.2023 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_13_07_2023_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE 13.07.2023 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_13_07_2023_ENGLISH.pdf"
                            },
                            {
                                name: "RECORD DATE 01.08.2023 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/RECORD_DATE_01_08_2023_ENGLISH.pdf"
                            },
                            {
                                name: "RECORD DATE 01.08.2023 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/RECORD_DATE_01_08_2023_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE 09.10.2023 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_09_10_2023_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE 09.10.2023 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_09_10_2023_ENGLISH.pdf"
                            },
                            {
                                name: "NOTICE OF POSTAL BALLOT 13.10.2023 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/NOTICE_OF_POSTAL_BALLOT_13_10_2023_TELUGU.pdf"
                            },
                            {
                                name: "NOTICE OF POSTAL BALLOT 13.10.2023 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/NOTICE_OF_POSTAL_BALLOT_13_10_2023_ENGLISH.pdf"
                            },
                            {
                                name: "RECORD DATE 12.11.2023 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/RECORD_DATE_12_11_2023_TELUGU.pdf"
                            },
                            {
                                name: "RECORD DATE 12.11.2023 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/RECORD_DATE_12_11_2023_ENGLISH.pdf"
                            },
                            {
                                name: "PAPER NOTICE Q3 19.01.2024 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_Q3_19_01_2024_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE Q3 19.01.2024 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2023-24/PAPER_NOTICE_Q3_19_01_2024_ENGLISH.pdf"
                            }
                            
                        ]
                    }, 
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "RESULTS_TELUGU 19.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/RESULTS_TELUGU_19_01_2025.pdf"
                            },
                            {
                                name: "RESULTS_ENGLISH 19.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/RESULTS_ENGLISH_19_01_2025.pdf"
                            },
                            {
                                name: "RESULTS_TELUGU 06.10.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/RESULTS_TELUGU_06_10_2024.pdf"
                            },
                            {
                                name: "RESULTS_ENGLISH 06.10.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/RESULTS_ENGLISH_06_10_2024.pdf"
                            },

                            {
                                name: "PAPER NOTICE Q4 25.04.2024 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/PAPER_NOTICE_Q4_25_04_2024_TELUGU.pdf"
                            },
                            {
                                name: "PAPER NOTICE Q4 25.04.2024 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/PAPER_NOTICE_Q4_25_04_2024_ENGLISH.pdf"
                            },
                            {
                                name: "AGM NOTICE 07.05.2024 - TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/AGM_NOTICE_07_05_2024_TELUGU.pdf"
                            },
                            {
                                name: "AGM NOTICE 07.05.2024 - ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/AGM_NOTICE_07_05_2024_ENGLISH.pdf"
                            },
                            {
                                name: "PAPER NOTICE_IEPF 11.07.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/PAPER_NOTICE_IEPF_11_07_2024.pdf"
                            },
                            {
                                name: "PAPER NOTICE 20.07.2024 ENGLISH",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/PAPER_NOTICE_20_07_2024_ENGLISH.pdf"
                            },
                            {
                                name: "PAPER NOTICE 20.07.2024 TELUGU",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/6. News Paper Publications/2024-25/PAPER_NOTICE_20_07_2024_TELUGU.pdf"
                            }

                        ]
                    }
                ],
                years: []
            },
            {
                name: "Terms and Conditions of Appointment of Independent Directors",
                menu3: [],
                years: [
                    {
                        name: "Terms of Appointment of Indpendent Directors",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/7. Terms and Conditions for Appointment of Directors/Terms of Appointment of Indpendent Directors.pdf"
                    },
                ]
            },
            {
                name: "Composition of  Committees of Board of Directors",
                menu3: [],
                years: [
                    {
                        name: "Composition of Board and Committees 27.03.2025",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/8. Composition of Board and Committees/Composition_of_Board_and_Committees_27_03_2025.pdf"
                    },
                    {
                        name: "Composition of Committees of Board of Directors",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/8. Composition of Board and Committees/Composition_of_Board_and_Committees.pdf"
                    }
                ]
            },
            {
                name: "Credit Ratings",
                menu3: [],
                years: [
                    {
                        name: "Credit Rating (CARE) 24.05.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/Credit_Rating_CARE_24_05_2024.pdf"
                    },
                    {
                        name: "REVISION OF CREDIT RATING 07.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/revision_of_credit_rating_07_03_24.pdf"
                    },
                    {
                        name: "Brickwork Credit Rating Withdrawn 26.08.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/brickwork_credit_rating_withdrawn_26_08_2023.pdf"
                    },
                    {
                        name: "Credit Rating Intimation 08-12-2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/Credit Rating Intimation 08-12-2022.pdf"
                    },
                   
                    {
                        name: "Revision of Credit Rating 19-08-2021",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/Revision of Credit Rating 19-08-2021.pdf"
                    },
                    {
                        name: "Revision of Credit Rating 09-12-2020",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/9. Credit Ratings/Revision of Credit Rating 09-12-2020.pdf"
                    }
                ]
            },
            {
                name: "Contact details of Key Managerial Personnel",
                menu3: [],
                years: [
                    {
                        name: "Contact details of Key Managerial Personnel",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/10. Contact details of KMP - Materiality Events/Contact details of KMP - Materiality Events.pdf"
                    }
                ]
            },
            {
                name: "Criteria of making payments to Non-Executive Directors",
                menu3: [],
                years: [
                    {
                        name: "Criteria of making payments to Non-Executive Directors",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/11. Criteria of making payments to Non-Executive Directors/Criteria of making payments to Non-Executive Directors.pdf"
                    }
                ]
            },
            {
                name: "Familiarization Programme",
                menu3: [],
                years: [
                    {
                        name: "Familiarization Programmes",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/12. Familiarization Programme/Familiarization Programmes.pdf"
                    }
                ]
            },
            {
                name: "Email address for Grievance Redressal",
                menu3: [],
                years: [
                    {
                        name: "Designated Official for Investor Grievances",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/13. Email address for Grievance Redressal/Designated Official for Investor Grievances.pdf"
                    }
                ]
            },
            {
                name: "Contact details for Investor Grievances",
                menu3: [],
                years: [
                    {
                        name: "Contact Details for Investor Grievances",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/14. Contact details for Investor Grievances/Contact Details for Investor Grievances.pdf"
                    }
                ]
            },
            {
                name: "Outcome of Meeting",
                menu3: [
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "Outcome 27.03.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_27_03_2025.pdf"
                            },
                            {
                                name: "Outcome 26.02.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_26_02_2025.pdf"
                            },
                            {
                                name: "Outcome 27.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_27_01_2025.pdf"
                            },
                            {
                                name: "Outcome of Board Meeting 18.01.2025",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_of_Board_Meeting_18_01_2025.pdf"
                            },
                            {
                                name: "Outcome of Board Meeting 26.12.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_26_12_2024.pdf"
                            },
                            {
                                name: "Outcome of Board Meeting 24.10.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_of_Board_Meeting_05_10_2024.pdf"
                            },
                            {
                                name: "Outcome of Board Meeting 05.10.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/Outcome_of_Board_Meeting_05_10_2024.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 25.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/OUTCOME_OF_BM_25_04_2024.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 19.07.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2024-25/OUTCOME_OF_BM_19_07_2024.pdf"
                            }
                           
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "OUTCOME OF BM 25.04.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME_OF_BM_25_04_2024.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 06.03.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME_OF_BM_06_03_2024.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 19.01.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME_OF_BM_19_01_2024.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 09.10.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME_OF_BM_09_10_2023.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 13-07-2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME OF BM 13-07-2023.pdf"
                            },
                            {
                                name: "OUTCOME OF BM 23-06-2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/OUTCOME OF BM 23.06.2023.pdf"
                            },
                           
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "Outcome of BM 17-05-23",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 46/40. Outcome of Meeting/2023-24/BM Outcome 17.05.2023.pdf"
                            },
                           
                        ]
                    }
                ],
                
            },
            {
                name: "Rights Issue 2024",
                menu3: [],
                years: [
                    {
                        name: "Outcome Rights Issue Committee 04.12.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/41.Rights Issue 2024/Outcome_Rights_Issue_Committee_04_12_2024.pdf"
                    },
                    {
                        name: "Outcome of Board Meeting 24.10.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/41.Rights Issue 2024/Outcome_of_Board_Meeting_24_10_2024.pdf"
                    },
                    {
                        name: "Board Meeting Intimation 21.10.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/41.Rights Issue 2024/board_meeting_intimation_21_10_2024.pdf"
                    },
                    {
                        name: "Closure of Trading Window 21.10.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 46/41.Rights Issue 2024/closure_of_trading_window_21_10_2024.pdf"
                    }
                ]
            },
        ]
    },
    {
        menu1: "Disclosure Under Regulation 62",
        menu2: [
            {
                name: "Corporate Governance",
                menu3: [
                    {
                        name: "2011-12",
                        years: [
                            {
                                name: "CG-30-06-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2011-12/CG-30-06-2011.pdf"
                            },
                            {
                                name: "CG-30-09-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2011-12/CG-30-09-2011.pdf"
                            },
                            {
                                name: "CG-31-03-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2011-12/CG-31-03-2012.pdf"
                            },
                            {
                                name: "CG-31-12-2011",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2011-12/CG-31-12-2011.pdf"
                            }
                        ]
                    },
                    {
                        name: "2012-13",
                        years: [
                            {
                                name: "CG-30-06-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2012-13/CG-30-06-2012.pdf"
                            },
                            {
                                name: "CG-30-09-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2012-13/CG-30-09-2012.pdf"
                            },
                            {
                                name: "CG-31-03-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2012-13/CG-31-03-2013.pdf"
                            },
                            {
                                name: "CG-31-12-2012",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2012-13/CG-31-12-2012.pdf"
                            }
                        ]
                    },
                    {
                        name: "2013-14",
                        years: [
                            {
                                name: "CG-30-06-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2013-14/CG-30-06-2013.pdf"
                            },
                            {
                                name: "CG-30-09-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2013-14/CG-30-09-2013.pdf"
                            },
                            {
                                name: "CG-31-03-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2013-14/CG-31-03-2014.pdf"
                            },
                            {
                                name: "CG-31-12-2013",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2013-14/CG-31-12-2013.pdf"
                            }
                        ]
                    },
                    {
                        name: "2014-15",
                        years: [
                            {
                                name: "CG-30-06-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2014-15/CG-30-06-2014.pdf"
                            },
                            {
                                name: "CG-30-09-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2014-15/CG-30-09-2014.pdf"
                            },
                            {
                                name: "CG-31-03-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2014-15/CG-31-03-2015.pdf"
                            },
                            {
                                name: "CG-31-12-2014",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2014-15/CG-31-12-2014.pdf"
                            }
                        ]
                    },
                    {
                        name: "2015-16",
                        years: [
                            {
                                name: "CG-30-06-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2015-16/CG-30-06-2015.pdf"
                            },
                            {
                                name: "CG-30-09-2015",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2015-16/CG-30-09-2015.pdf"
                            }
                        ]
                    },
                    {
                        name: "2016-17",
                        years: []
                    },
                    {
                        name: "2017-18",
                        years: []
                    },
                    {
                        name: "2018-19",
                        years: [
                            {
                                name: "CG-30-06-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2018-19/CG-30-06-2018.pdf"
                            },
                            {
                                name: "CG-30-09-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2018-19/CG-30-09-2018.pdf"
                            },
                            {
                                name: "CG-31-03-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2018-19/CG-31-03-2019.pdf"
                            },
                            {
                                name: "CG-31-12-2018",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2018-19/CG-31-12-2018.pdf"
                            }
                        ]
                    },
                    {
                        name: "2019-20",
                        years: [
                            {
                                name: "CG-30-06-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2019-20/CG-30-06-2019.pdf"
                            },
                            {
                                name: "CG-30-09-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2019-20/CG-30-09-2019.pdf"
                            },
                            {
                                name: "CG-31-03-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2019-20/CG-31-03-2020.pdf"
                            },
                            {
                                name: "CG-31-12-2019",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2019-20/CG-31-12-2019.pdf"
                            }
                        ]
                    },
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "CG-30-06-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2020-21/CG-30-06-2020.pdf"
                            },
                            {
                                name: "CG-30-09-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2020-21/CG-30-09-2020.pdf"
                            },
                            {
                                name: "CG-31-03-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2020-21/CG-31-03-2021.pdf"
                            },
                            {
                                name: "CG-31-12-2020",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2020-21/CG-31-12-2020.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "CG-30-06-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2021-22/CG-30-06-2021.pdf"
                            },
                            {
                                name: "CG-30-09-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2021-22/CG-30-09-2021.pdf"
                            },
                            {
                                name: "CG-31-03-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2021-22/CG-31-03-2022.pdf"
                            },
                            {
                                name: "CG-31-12-2021",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2021-22/CG-31-12-2021.pdf"
                            }
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "CG-30-06-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2022-23/CG-30-06-2022.pdf"
                            },
                            {
                                name: "CG-30-09-2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2022-23/CG-30-09-2022.pdf"
                            },
                            {
                                name: "CG-31.12.2022",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2022-23/CG-31.12.2022.pdf"
                            },
                            {
                                name: "CG-31-03-2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2022-23/CG-31-03-2023.pdf"
                            },
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "CG-30-06-2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2023-24/CG-30-06-2023.pdf"
                            },
                            {
                                name: "CG 30.09.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2023-24/CG_30_09_2023.pdf"
                            },
                            {
                                name: "CG 31.12.2023",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2023-24/CG_31_12_2023.pdf"
                            },
                            {
                                name: "CG 31.03.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2023-24/CG_31_03_2024.pdf"
                            }
                        ]
                    },
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "CG 30.09.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2024-25/CG-30-09-2024.pdf"
                            },
                            {
                                name: "CG 30.06.2024",
                                pdf: "InvestorsNewData/Disclosure Under Regulation 62/15. Corporate Governance/2024-25/CG-30-06-2024.pdf"
                            }
                        ]
                    }
                ],
                years: []
            },
            {
                name: "Investor Grievance Reports",
                menu3: [],
                years: [
                    // {
                    //     name: "INVESTOR GRIEVANCES 30.06.2022",
                    //     pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/Investor Grievances 30.06.2022.pdf"
                    // },
                    {
                        name: "INVESTOR GRIEVANCES 30.09.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/Investor Grievances 30.09.2022.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 31.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR GRIEVANCES 31.12.2022.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR-GRIEVANCES-31-03-2023.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 30.06.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR-GRIEVANCES-30-06-2023.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 30.09.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR_GRIEVANCES_30_09_2023.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 31.12.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR_GRIEVANCES_31_12_2023.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR_GRIEVANCES_31_03_2024.pdf"
                    },
                    {
                        name: "INVESTOR GRIEVANCES 30.06.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/16. Investor Grievance Reports/INVESTOR-GRIEVANCES-30-06-2024.pdf"
                    }
                ]
            },
            {
                name: "Reconciliation of Share Capital Audit Report",
                menu3: [],
                years: [
                    {
                        name: "Reconciliation of Share Capital Audit 31.12.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/Reconciliation-of-Share-Capital-Audit-31-12-2024.pdf"
                    },
                    {
                        name: "Reconciliation of Share Capital Audit 30.06.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/Reconciliation of Share Capital Audit 30.06.2022.pdf"
                    },
                    {
                        name: "Reconciliation of Share Capital Audit 30.09.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/Reconciliation of Share Capital Audit 30.09.2022.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT 31.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION OF SHARE CAPITAL AUDIT 31.12.2022.pdf"
                    },
                    {
                        name: "Reconciliation Of Share Capital Audit Report 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION-OF-SHARE-CAPITAL-AUDIT-31-03-2023.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT 30.06.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION OF SHARE CAPITAL AUDIT 30.06.2023.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT REPORT 30.09.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION_OF_SHARE_CAPITAL_AUDIT_REPORT_30_09_2023.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT REPORT 31.12.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION_OF_SHARE_CAPITAL_AUDIT_REPORT_31_12_2023.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT REPORT 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION_OF_SHARE_CAPITAL_AUDIT_REPORT_31_03_2024.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT REPORT 30.06.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/RECONCILIATION_OF_SHARE_CAPITAL_AUDIT_REPORT_30_06_2024.pdf"
                    },
                    {
                        name: "RECONCILIATION OF SHARE CAPITAL AUDIT REPORT 30.09.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/17. Reconciliation of Share Capital Audit/reconciliation_of_share_capital_audit_report_30_09_2024.pdf"
                    }
                    
                ]
            },
            {
                name: "Regulation - 40(10) - Timely Transfer",
                menu3: [],
                years: [
                    {
                        name: "Compliance Certificate under Reg. 40(9) - 31.03.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/18. Reg. 40(10) - Timely Transfer/Compliance Certificate under Reg. 40(9) - 31.03.2022.pdf"
                    },
                    {
                        name: "Compliance Certificate under Reg. 40(9) - 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/18. Reg. 40(10) - Timely Transfer/COMPLIANCE-CERTIFICATE-UNDER-REG-40-9-31.03.2023.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE UNDER REG.40(9) - 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/18. Reg. 40(10) - Timely Transfer/COMPLIANCE_CERTIFICATE_UNDER_REG_40_9_31_03_2024.pdf"
                    }
                ]
            },
            {
                name: "Regulation - 74(5) Credit of Shares",
                menu3: [],
                years: [
                    {
                        name: "Compliance Certificate 09.01.2025",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/Compliance-Certificate-09-01-2025.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG.74(5) 30.09.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE-CERTIFICATE-REG-74-5-30-09-2024.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG.74(5) 30.06.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE-CERTIFICATE-REG-74-5-30-06-2024.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG.74(5) 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE_CERTIFICATE_REG_74_5_31_03_2024.pdf"
                    }
                    ,
                    {
                        name: "COMPLIANCE CERTIFICATE REG.74(5) 31.12.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE_CERTIFICATE_REG_74_5_31_12_2023.pdf"
                    },

                    {
                        name: "COMPLIANCE CERTIFICATE REG.74(5) - 30.09.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE-CERTIFICATE-REG-74-5-30-09-2023.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG. 74(5) - 30.06.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE-CERTIFICATE-REG-74-5-30-06-2023.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG.75(4) - 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE-CERTIFICATE-REG-74-5-31-03-2023.pdf"
                    },

                    {
                        name: "COMPLIANCE CERTIFICATE REG.75(4) - 31.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/COMPLIANCE CERTIFICATE REG.75(4) - 31.12.2022.pdf"
                    },
                    {
                        name: "Compliance Certificate Reg. 74(5) - 30.09.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/Compliance Certificate Reg. 74(5) - 30.09.2022.pdf"
                    },
                    {
                        name: "Compliance Certificate Reg. 74(5) - 30.06.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/19. Reg. 74(5) - Credit of Shares/Compliance Certificate Reg. 74(5) - 30.06.2022.pdf"
                    }
                ]
            },
            {
                name: "Regulation - 7(3) - Common Registry Certificate",
                menu3: [],
                years: [
                    {
                        name: "Compliance Certificate Reg. 7(3) 30.06.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/20. Reg. 7(3) - Common Registry Certificate/Compliance Certificate Reg. 7(3) 30.06.2022.pdf"
                    },
                    {
                        name: "Compliance Certificate Reg. 7(3) 30.09.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/20. Reg. 7(3) - Common Registry Certificate/Compliance Certificate Reg. 7(3) 30.09.2022.pdf"
                    },
                    {
                        name: "Compliance Certificate Reg. 7(3) 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/20. Reg. 7(3) - Common Registry Certificate/COMPLIANCE-CERTIFICATE-REG-7-3-31-03-2023.pdf"
                    },
                    {
                        name: "COMPLIANCE CERTIFICATE REG.7(3) 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/20. Reg. 7(3) - Common Registry Certificate/COMPLIANCE_CERTIFICATE_REG_7_3_31_03_2024.pdf"
                    }
                ]
            },
            {
                name: "Regulation 23(9) – Related Party Transactions",
                menu3: [],
                years: [
                    {
                        name: "RELATED PARTY TRANSACTIONS H1 30.09.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/RPT_H1_30_09_2024.pdf"
                    },
                    {
                        name: "RELATED PARTY TRANSACTIONS H2 - 31.03.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/related_party_transactions_h2_31_03_2024.pdf"
                    },
                    {
                        name: "RELATED PARTY TRANSACTIONS 30.09.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/related_party_transactions_30_09_2023.pdf"
                    },
                    {
                        name: "RELATED PARTY TRANSACTIONS 31.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/related_party_transactions_31.03.2023.pdf"
                    },
                    {
                        name: "Related Party Transactions 30.09.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/Related Party Transactions 30.09.2022.pdf"
                    },
                    {
                        name: "Related Party Transactions 31.03.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/21. Reg. 23(9) – Related Party Transactions/Related Party Transactions 31.03.2022.pdf"
                    },
                ]
            },
            {
                name: "Other Disclosures",
                menu3: [],
                years: [  
                    {
                        name: "INTIMATION - ISSUE OF DUPLICATE SHARE CERTIFICATE 02.02.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation_Issue_of_duplicate_share_certificate_02_02_2024.pdf"
                    },
                    {
                    name: "INTIMATION - LOSS OF SHARE CERTIFICATE 17.01.2024 - BHAVNA G SHAH",
                    pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/INTIMATION-LOSS-OF-SHARE-CERTIFICATE-17-01-2024-BHAVNA-G-SHAH.pdf"
                    },
                     {
                        name: "INTIMATION - ISSUE OF DUPLICATE SHARE CERTIFICATE 12.01.2024",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/INTIMATION-ISSUE-OF-DUPLICATE-SHARE-CERTIFICATE-12-01-2024.pdf"
                    },
                    {
                        name: "INTIMATION - LOSS OF SHARE CERTIFICATE 27.12.2023 - HITESH MANILAL",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/INTIMATION-LOSS-OF-SHARE-CERTIFICATE-27-12-2023-HITESH-MANILAL.pdf"
                    },
                    {
                        name: "INTIMATION - LOSS OF SHARE CERTIFICATE 09.05.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-09-05-2023.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 15.04.2023 - Mr. AJIT",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-15-04-2023-1.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 15.04.2023 - MRS. MADHURI",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-15-04-2023-2.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 15.04.2023 - MR. S SANJAY JAIN",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-15-04-2023-3.pdf"
                    },
                    {
                        name: "Large Corporate - Annual Disclosure 14.04.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Large Corporate - Annual Disclosure 14.04.2023.pdf"
                    },
                    {
                        name: "Large Corporate - Initial Disclosure 14.04.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Large Corporate - Initial Disclosure 14.04.2023.pdf"
                    },
                    {
                        name: "Intimation - Issue of Duplicate Share Certificate 28.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Issue of Duplicate Share Certificate 28.03.2023.pdf"
                    },
                    {
                        name: "Intimation - Issue of Duplicate Share Certificate 27.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Issue of Duplicate Share Certificate 27.03.2023.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 02.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-02-03-2023.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 02.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-02-03-2023.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 10.03.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation-Loss-of-share-certificate-10-03-2023.pdf"
                    },
                    {
                        name: "Intimation - Issue of Duplicate Share Certificate 06.02.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Issue of Duplicate Share Certificate 06.02.2023.pdf"
                    },
                    {
                        name: "Intimation - ISSUE OF DUPLICATE SHARE CERTIFICATE  20.01.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/INTIMATION - ISSUE OF DUPLICATE SHARE CERTIFICATE  20.01.2023.pdf"
                    },
                    {
                        name: "Intimation - LOSS OF SHARE CERTIFICATE 20.01.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/INTIMATION - LOSS OF SHARE CERTIFICATE 20.01.2023.pdf"
                    },
                    {
                        name: "SDD Compliance Certificate 20.01.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/SDD Compliance Certificate 20.01.2023.pdf"
                    },
                    {
                        name: "Intimation - Issue of Duplicate Share Certificate 09.01.2023",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Issue of Duplicate Share Certificate 09.01.2023.pdf"
                    },
                    {
                        name: "Intimation - Issue of Duplicate Share Certificate 19.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Issue of Duplicate Share Certificate 19.12.2022.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 15.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Loss of Share Certificate 15.12.2022.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 28.11.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Loss of Share Certificate 28.11.2022.pdf"
                    },
                    {
                        name: "Intimation - Loss of Share Certificate 30.12.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/Intimation - Loss of Share Certificate 30.12.2022.pdf"
                    },
                    {
                        name: "SDD Compliance Certificate 18.11.2022",
                        pdf: "InvestorsNewData/Disclosure Under Regulation 62/22. Other Disclosures/SDD Compliance Certificate 18.11.2022.pdf"
                    }
                    
                ]
            }
        ],
    },
    {
        menu1: "Shareholders Information",
        menu2: [
            {
                name: "Shares Transferred to IEPF",
                menu3: [],
                years: [
                   
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2024-25",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2024_25.pdf"
                    }, 
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2023-24",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2023_24.pdf"
                    }, 
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2023-24 SPLIT",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2023_24_SPLIT.pdf"
                    }, 
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2023-24 BONUS",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2023_24_BONUS.pdf"
                    }, 
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2022-23 BONUS",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2022_23_BONUS.pdf"
                    }, 
                    {
                        name: "List of Shares Transferred to IEPF 2022-23",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/List of Shares Transferred to IEPF 2022-23.pdf"
                    },
                    {
                        name: "List of Shares Transferred to IEPF 2020-21",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/List of Shares Transferred to IEPF 2020-21.pdf"
                    },
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2019-20",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2019_20.pdf"
                    },
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2018-19",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2018_19.pdf"
                    },
                    {
                        name: "LIST OF SHARES TRANSFERRED TO IEPF 2017-18",
                        pdf: "InvestorsNewData/Shareholders Information/31. Shares Transferred to IEPF/LIST_OF_SHARES_TRANSFERRED_TO_IEPF_2017_18.pdf"
                    },



                    
                ]
            },
            {
                name: "Dividend Transferred to IEPF",
                menu3: [],
                years: [
                   
                    {
                        name: "Dividend Transferred to IEPF 2016-17",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2016_17.pdf"
                    },
                    {
                        name: "Dividend Transferred to IEPF 2015-16",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2015_16.pdf"
                    },
                    {
                        name: "Dividend Transferred to IEPF 2014-15",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/Dividend Transferred to IEPF 2014-15.pdf"
                    },
                    {
                        name: "DIVIDEND TRANSFERRED TO IEPF 2012-13",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2012_13.pdf"
                    },
                    {
                        name: "DIVIDEND TRANSFERRED TO IEPF 2011-12",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2011_12.pdf"
                    },
                    {
                        name: "DIVIDEND TRANSFERRED TO IEPF 2010-11",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2010_11.pdf"
                    },
                    {
                        name: "DIVIDEND TRANSFERRED TO IEPF 2009-10",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2009_10.pdf"
                    },
                    {
                        name: "DIVIDEND TRANSFERRED TO IEPF 2008-09",
                        pdf: "InvestorsNewData/Shareholders Information/32. Dividend Transferred to IEPF/DIVIDEND_TRANSFERRED_TO_IEPF_2008_09.pdf"
                    }
                ]
            },
            {
                name: "Unclaimed dividends",
                menu3: [],
                years: [
                    {
                        name: "FY 2023-24 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY_2023_24_Final_Dividend.pdf"
                    },
                    {
                        name: "FY 2022-23 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY_2022_23_Final_Dividend.pdf"
                    },
                    {
                        name: "FY 2021-22 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2021-22 Final Dividend.pdf"
                    },
                    {
                        name: "FY 2020-21 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2020-21 Final Dividend.pdf"
                    },
                    {
                        name: "FY 2019-20 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2019-20 Final Dividend.pdf"
                    },
                    {
                        name: "FY 2019-20 - Interim Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2019-20 - Interim Dividend.pdf"
                    },
                    {
                        name: "FY 2018-19 - Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2018-19 - Final Dividend.pdf"
                    },
                    {
                        name: "FY 2018-19 - Interim Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2018-19 - Interim Dividend.pdf"
                    },
                    {
                        name: "FY 2017-18 Final Dividend",
                        pdf: "InvestorsNewData/Shareholders Information/39. Unclaimed Dividends/FY 2017-18 Final Dividend.pdf"
                    }
                ]
            },
            {
                name: "KYC Forms for updation",
                menu3: [],
                years: [ 
                    {
                        name: "Form ISR-4.pdf",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/form_isr_4.pdf"
                    },
                    {
                        name: "ECS Mandate Form",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/ECS Mandate Form.pdf"
                    },
                    {
                        name: "Form - 10F",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form - 10F.pdf"
                    },
                    {
                        name: "Form - 15G",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form - 15G.pdf"
                    },
                    {
                        name: "Form - 15H",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form - 15H.pdf"
                    },
                    {
                        name: "Form ISR - 1 (for Updation of KYC Documents)",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form ISR - 1 (for Updation of KYC Documents).pdf"
                    },
                    {
                        name: "Form ISR - 2 (for Confirmation of Signature)",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form ISR - 2 (for Confirmation of Signature).pdf"
                    },
                    {
                        name: "Form ISR - 3 (for Opting-out Nomination by Shareholdres)",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form ISR - 3 (for Opting-out Nomination by Shareholdres).pdf"
                    },
                    {
                        name: "Form SH - 13 & SH - 14",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Form SH - 13 & SH - 14.pdf"
                    },
                    {
                        name: "Restriction on Transfer of Shares in Physical form",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Restriction on Transfer of Shares in Physical form.pdf"
                    },
                    {
                        name: "Shareholder Self Declaration Form for 15H or 15G",
                        pdf: "InvestorsNewData/Shareholders Information/34. KYC Forms for updation/Shareholder Self Declaration Form for 15H or 15G.pdf"
                    },
                ]
            },
            {
                name: "Procedures and Forms",
                menu3: [],
                years: [
                    {
                        name: "Procedure for Issue of Duplicate Share Certificates",
                        pdf: "InvestorsNewData/Shareholders Information/35. Procedures and Forms/Procedure for Issue of Duplicate Share Certificates.pdf"
                    },
                    {
                        name: "Procedure for Transmission of Shares",
                        pdf: "InvestorsNewData/Shareholders Information/35. Procedures and Forms/Procedure for Transmissiion of Shares.pdf"
                    },
                ]
            },
            {
                name: "Registrar and Share Transfer Agent",
                menu3: [],
                years: [{
                    name: "Registrar and Share Transfer Agent",
                    pdf: "InvestorsNewData/Shareholders Information/36. Registrar and Share Transfer Agent/Registrar and Share Transfer Agent.pdf"
                }]
            },
            {
                name: "Buyback",
                menu3: [],
                years: [
                    {
                        name: "buyback-4.6.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-4.6.2014.pdf"
                    },
                    {
                        name: "buyback-4.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-4.8.2014.pdf"
                    },
                    {
                        name: "buyback-2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-2014.pdf"
                    },
                    {
                        name: "buyback-dr-1.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-1.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-2.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-2.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-3.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-3.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-4.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-4.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-5.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-5.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-8.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-8.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-9.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-9.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-10.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-10.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-11.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-11.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-11.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-11.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-12.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-12.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-12.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-12.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-13.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-13.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-14.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-14.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-15.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-15.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-16.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-16.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-17.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-17.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-18.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-18.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-18.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-18.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-19.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-19.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-19.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-19.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-20.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-20.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-21.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-21.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-22.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-22.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-22.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-22.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-23.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-23.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-24.9.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-24.9.2014.pdf"
                    },
                    {
                        name: "buyback-dr-25.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-25.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-26.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-26.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-27.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-27.8.2014.pdf"
                    },
                    {
                        name: "buyback-dr-28.8.2014",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback-dr-28.8.2014.pdf"
                    },
                    {
                        name: "buyback",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/buyback.pdf"
                    },
                    {
                        name: "closure of buy-back-18.09.2014 final",
                        pdf: "InvestorsNewData/Shareholders Information/37. Buyback/closure of buy-back-18.09.2014 final.pdf"
                    },
                ]
            },
            {
                name: "Amalgamation",
                menu3: [],
                years: [
                    {
                        name: "1. Board Resolution",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/1. Board Resolution.pdf"
                    },
                    {
                        name: "2. Scheme of Amalgamation",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/2. Scheme of Amalgamation.pdf"
                    },
                    {
                        name: "3. Report of the Audit Commitee",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/3. Report of the Audit Commitee.pdf"
                    },
                    {
                        name: "4. Valuation Report",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/4. Valuation Report.pdf"
                    },
                    {
                        name: "5. Fairness opinion",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/5. Fairness opinion.pdf"
                    },
                    {
                        name: "6. Pre and Post Shareholding pattern",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/6. Pre and Post Shareholding pattern.pdf"
                    },
                    {
                        name: "7. Audit Financial details for the last three years",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/7. Audit Financial details for the last three years.pdf"
                    },
                    {
                        name: "8. CA Certificate",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/8. CA Certificate.pdf"
                    },
                    {
                        name: "9. complaints-report",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/9. complaints-report.pdf"
                    },
                    {
                        name: "10. Pre & Post Shareholding",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/10. Pre & Post Shareholding.pdf"
                    },
                    {
                        name: "11. Bse Observation Letter",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/11. Bse Observation Letter.pdf"
                    },
                    {
                        name: "12. Notice-of-CCM",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/12. Notice-of-CCM.pdf"
                    },
                    {
                        name: "13. Notice-of-Postal-Ballot-&-E-voting",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/13. Notice-of-Postal-Ballot-&-E-voting.pdf"
                    },
                    {
                        name: "14. Postal-Ballot-Form",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/14. Postal-Ballot-Form.pdf"
                    },
                    {
                        name: "15. Proxy-Form",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/15. Proxy-Form.pdf"
                    },
                    {
                        name: "16. Paper-Advertisements",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/16. Paper-Advertisements.pdf"
                    },
                    {
                        name: "17. Combined-Scrutinizer-Report",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/17. Combined-Scrutinizer-Report.pdf"
                    },
                    {
                        name: "18. Proceedings-of-the-CCM",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/18. Proceedings-of-the-CCM.pdf"
                    },
                    {
                        name: "19. Scrutinizer-Report-E-voting",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/19. Scrutinizer-Report-E-voting.pdf"
                    },
                    {
                        name: "20. Scrutinizer-Report-PB-&-Evoting",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/20. Scrutinizer-Report-PB-&-Evoting.pdf"
                    },
                    {
                        name: "21. Scrutinizer-Report-Poll",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/21. Scrutinizer-Report-Poll.pdf"
                    },
                    {
                        name: "22. Voting-Result",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/22. Voting-Result.pdf"
                    },
                    {
                        name: "23. PB-Evoting-Poll",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/23. PB-Evoting-Poll.pdf"
                    },
                    {
                        name: "24. NCLT_Order",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/24. NCLT_Order.pdf"
                    },
                    {
                        name: "25. NCLAT_Order",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/25. NCLAT_Order.pdf"
                    }
                ]
            },
            {
                name: "SCORES Portal Web Address",
                menu3: [],
                years: [
                    {
                        name: "SCORES Portal Web Address",
                        pdf: "InvestorsNewData/Shareholders Information/38. Amalgamation/SCORES_Portal_Web_Address.pdf"
                    }
                ]
            }
        ]
    },
    {
        menu1: "Financial Information",
        menu2: [
            {
                name: "Notice of Board Meetings",
                menu3: [
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "Board_Meeting_Intimation_02_05_2020",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2020-21/Board_Meeting_Intimation_02_05_2020.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_13_01_2021",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2020-21/Board_Meeting_Intimation_13_01_2021.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_16_10_2020",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2020-21/Board_Meeting_Intimation_16_10_2020.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_17_07_2020",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2020-21/Board_Meeting_Intimation_17_07_2020.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "Board_Meeting_Intimation_07_07_2021",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2021-22/Board_Meeting_Intimation_07_07_2021.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_10_01_2022",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2021-22/Board_Meeting_Intimation_10_01_2022.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_16_10_2021",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2021-22/Board_Meeting_Intimation_16_10_2021.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_17_04_2021",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2021-22/Board_Meeting_Intimation_17_04_2021.pdf"
                            }
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "Board_Meeting_Intimation_11_10_2022",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2022-23/Board_Meeting_Intimation_11_10_2022.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_14_07_2022",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2022-23/Board_Meeting_Intimation_14_07_2022.pdf"
                            },
                            {
                                name: "Board_Meeting_Intimation_25_04_2022",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2022-23/Board_Meeting_Intimation_25_04_2022.pdf"
                            },
                            {
                                name: "BOARD_MEETING_INTIMATION_03_01_2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2022-23/BOARD_MEETING_INTIMATION_03_01_2023.pdf"
                            }
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "Board Meeting Intimation 09-05-2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_09_05_2023.pdf"
                            },
                            {
                                name: "Board Meeting Intimation 01-04-2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_01_04_2023.pdf"
                            },
                            {
                                name: "Board Meeting Intimation 07-06-2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_07_06_2023.pdf"
                            },
                            {
                                name: "Board Meeting Intimation 03-07-2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_03_07_2023.pdf"
                            },
                            {
                                name: "Board Meeting Intimation 28-09-2023",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_28_09_2023.pdf"
                            },
                            {
                                name: "BOARD MEETING INTIMATION 09.01.2024",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2023-24/BM_Intimation_09_01_2024.pdf"
                            }
                        ]
                    },
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "BM Intimation 11.01.2025",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2024-25/BM_Intimation_11_01_2025.pdf"
                            },
                            {
                                name: "BM INTIMATION  21.10.2024",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2024-25/BM_INTIMATION_21_10_2024.pdf"
                            },
                            {
                                name: "BM INTIMATION 25.09.2024",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2024-25/BM_INTIMATION_25_09_2024.pdf"
                            },
                            {
                                name: "BM INTIMATION 08.07.2024",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2024-25/BM_INTIMATION_08_07_2024.pdf"
                            },
                            {
                                name: "BM INTIMATION 15.04.2024",
                                pdf: "InvestorsNewData/Financial Information/23. Notice of Board Meetings/2024-25/BM_INTIMATION_15_04_2024.pdf"
                            },
                        ]
                    }

                ],
                years: []
            },
            {
                name: "Financial Results",
                menu3: [
                    {
                        name: "2014-15",
                        years: [
                            {
                                name: "Financial Results 30.06.2014",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2014-15/Financial Results 30.06.2014.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2015",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2014-15/Financial Results 31.03.2015.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2014",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2014-15/Financial Results 31.12.2014.pdf"
                            }
                        ]
                    },
                    {
                        name: "2015-16",
                        years: [
                            {
                                name: "Financial Results 30.06.2015",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2015-16/Financial Results 30.06.2015.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2015",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2015-16/Financial Results 30.09.2015.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2016",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2015-16/Financial Results 31.03.2016.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2015",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2015-16/Financial Results 31.12.2015.pdf"
                            }
                        ]
                    },
                    {
                        name: "2016-17",
                        years: [
                            {
                                name: "Financial Results 30.06.2016",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2016-17/Financial Results 30.06.2016.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2016",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2016-17/Financial Results 30.09.2016.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2017",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2016-17/Financial Results 31.03.2017.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2016",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2016-17/Financial Results 31.12.2016.pdf"
                            }
                        ]
                    },
                    {
                        name: "2017-18",
                        years: [
                            {
                                name: "Financial Results 30.06.2017",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2017-18/Financial Results 30.06.2017.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2017",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2017-18/Financial Results 30.09.2017.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2018",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2017-18/Financial Results 31.03.2018.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2017",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2017-18/Financial Results 31.12.2017.pdf"
                            }
                        ]
                    },
                    {
                        name: "2018-19",
                        years: [
                            {
                                name: "Financial Results 30.6.2018",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2018-19/Financial Results 30.6.2018.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2018",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2018-19/Financial Results 30.09.2018.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2019",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2018-19/Financial Results 31.03.2019.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2018",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2018-19/Financial Results 31.12.2018.pdf"
                            }
                        ]
                    },
                    {
                        name: "2019-20",
                        years: [
                            {
                                name: "Financial Results 30.06.2019",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2019-20/Financial Results 30.06.2019.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2019",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2019-20/Financial Results 30.09.2019.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2020",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2019-20/Financial Results 31.03.2020.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2019",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2019-20/Financial Results 31.12.2019.pdf"
                            }
                        ]
                    },
                    {
                        name: "2020-21",
                        years: [
                            {
                                name: "Financial Results 30.06.2020",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2020-21/Financial Results 30.06.2020.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2020",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2020-21/Financial Results 30.09.2020.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2021",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2020-21/Financial Results 31.03.2021.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2020",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2020-21/Financial Results 31.12.2020.pdf"
                            }
                        ]
                    },
                    {
                        name: "2021-22",
                        years: [
                            {
                                name: "Financial Results 30.06.2021",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2021-22/Financial Results 30.06.2021.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2021",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2021-22/Financial Results 30.09.2021.pdf"
                            },
                            {
                                name: "Financial Results 31.03.2022",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2021-22/Financial Results 31.03.2022.pdf"
                            },
                            {
                                name: "Financial Results 31.12.2021",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2021-22/Financial Results 31.12.2021.pdf"
                            }
                        ]
                    },
                    {
                        name: "2022-23",
                        years: [
                            {
                                name: "Financial Results 30.06.2022",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2022-23/Financial Results 30.06.2022.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2022",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2022-23/Financial Results 30.09.2022.pdf"
                            },
                            {
                                name: "FINANCIAL RESULTS 31.12.2022",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2022-23/FINANCIAL RESULTS 31.12.2022.pdf"
                            }
                            ,
                            {
                                name: "FINANCIAL RESULTS 31.03.2023",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2022-23/financila-results-31-03-23.pdf"
                            }
                        ]
                    },
                    {
                        name: "2023-24",
                        years: [
                            {
                                name: "FINANCIAL RESULTS 25.04.2024",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2023-24/FINANCIAL_RESULTS_25_04_2024.pdf"
                            },
                            {
                                name: "FINANCIAL RESULTS 19.01.2024",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2023-24/FINANCIAL_RESULTS_19_01_2024.pdf"
                            },
                            {
                                name: "FINANCIAL RESULTS 09.10.2023",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2023-24/FINANCIAL_RESULTS_09_10_2023.pdf"
                            },
                            {
                                name: "FINANCIAL RESULTS 13.07.2023",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2023-24/FINANCIAL RESULTS 13.07.2023.pdf"
                            }
                        ]
                    },
                    {
                        name: "2024-25",
                        years: [
                            {
                                name: "Financial Results 31.12.2024",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2024-25/Financial_Results_31_12_2024.pdf"
                            },
                            {
                                name: "Financial Results 30.09.2024",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2024-25/Financial_Results_30_09_2024.pdf"
                            },
                            {
                                name: "FINANCIAL RESULTS 30.06.2024",
                                pdf: "InvestorsNewData/Financial Information/24. Financial Results/2024-25/FINANCIAL_RESULTS_30_06_2024.pdf"
                            }
                        ]
                    }
                ],
                years: []
            },
            {
                name: "Annual Reports",
                menu3: [],
                years: [
                    {
                        name: "Annual Report 2007-08",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2007-08.pdf"
                    },
                    {
                        name: "Annual Report 2008-09",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2008-09.pdf"
                    },
                    {
                        name: "Annual Report 2009-10",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2009-10.pdf"
                    },
                    {
                        name: "Annual Report 2010-11",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2010-11.pdf"
                    },
                    {
                        name: "Annual Report 2011-12",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2011-12.pdf"
                    },
                    {
                        name: "Annual Report 2012-13",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2012-13.pdf"
                    },
                    {
                        name: "Annual Report 2013-14",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2013-14.pdf"
                    },
                    {
                        name: "Annual Report 2014-15",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2014-15.pdf"
                    },
                    {
                        name: "Annual Report 2015-16",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2015-16.pdf"
                    },
                    {
                        name: "Annual Report 2016-17",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2016-17.pdf"
                    },
                    {
                        name: "Annual Report 2017-18",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2017-18.pdf"
                    },
                    {
                        name: "Annual Report 2018-19",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2018-19.pdf"
                    },
                    {
                        name: "Annual Report 2019-20",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2019-20.pdf"
                    },
                    {
                        name: "Annual Report 2020-21",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2020-21.pdf"
                    },
                    {
                        name: "Annual Report 2021-22",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2021-22.pdf"
                    },
                    {
                        name: "Annual Report 2022-23",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual Report 2022-23.pdf"
                    },
                    {
                        name: "Annual Report 2023-24",
                        pdf: "InvestorsNewData/Financial Information/25. Annual Reports/Annual_Report_2023_24.pdf"
                    },
                ]
            },
            {
                name: "Financials of Subsidiary Companies",
                menu3: [],
                years: [
                    {
                        name: "FINANCIAL STATEMENTS OF SUBSIDIARY FY 2023-24",
                        pdf: "InvestorsNewData/Financial Information/26. Financials of Subsidiary Companies/FINANCIAL_STATEMENTS_OF_SUBSIDIARY_FY_2023_24.pdf"
                    },
                    {
                        name: "FINANCIAL STATEMENTS OF SUBSIDIARY FY 2022-23",
                        pdf: "InvestorsNewData/Financial Information/26. Financials of Subsidiary Companies/FINANCIAL STATEMENTS OF SUBSIDIARY FY 2022-23.pdf"
                    },
                    {
                        name: "Financial Statements of Subsidiary FY 2021-22",
                        pdf: "InvestorsNewData/Financial Information/26. Financials of Subsidiary Companies/Financial Statements of Subsidiary FY 2021-22.pdf"
                    }
                ]
            },
            {
                name: "Annual Return",
                menu3: [],
                years: [
                    {
                        name: "Annual Return FY 2020-21",
                        pdf: "InvestorsNewData/Financial Information/27.  Annual Return/Annual Return FY 2020-21.pdf"
                    },
                    {
                        name: "Annual Return FY 2021-22",
                        pdf: "InvestorsNewData/Financial Information/27.  Annual Return/Annual Return FY 2021-22.pdf"
                    },
                    {
                        name: "Annual Return FY 2022-23",
                        pdf: "InvestorsNewData/Financial Information/27.  Annual Return/Annual Return FY 2022-23.pdf"
                    },
                    {
                        name: "ANNUAL RETURN FY 2023-24",
                        pdf: "InvestorsNewData/Financial Information/27.  Annual Return/ANNUAL_RETURN_FY_2023_24.pdf"
                    }
                ]
            },
            {
                name: "AGM - EGM Proceedings",
                menu3: [],
                years: [
                    
                    {
                        name: "Outcome of AGM 2015",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2015.pdf"
                    },
                    {
                        name: "Outcome of AGM 2016",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2016.pdf"
                    },
                    {
                        name: "Outcome of AGM 2017",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2017.pdf"
                    },
                    {
                        name: "Outcome of AGM 2018",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2018.pdf"
                    },
                    {
                        name: "Outcome of AGM 2019",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2019.pdf"
                    },
                    {
                        name: "Outcome of AGM 2020",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2020.pdf"
                    },
                    {
                        name: "Outcome of AGM 2021",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2021.pdf"
                    },
                    {
                        name: "AGM Proceedings Transcript 05-06-2021",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/AGM Proceedings Transcript 05-06-2021.pdf"
                    },
                    {
                        name: "AGM Proceedings Transcript 30-05-2022",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/AGM Proceedings Transcript 30-05-2022.pdf"
                    },
                    {
                        name: "Outcome of AGM 2022",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Outcome of AGM 2022.pdf"
                    },
    
                    {
                        name: "Outcome of AGM 2023",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/OUTCOME OF AGM 2023.pdf"
                    },
                    {
                        name: "AGM TRANSCRIPT 23.06.2023",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/AGM TRANSCRIPT 23.06.2023.pdf"
                    },
                    {
                        name: "OUTCOME OF AGM 2024",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/OUTCOME_OF_AGM_2024.pdf"
                    },
                    {
                        name: "Avantel Limited - AGM Transcript 30.05.2024",
                        pdf: "InvestorsNewData/Financial Information/28. AGM - EGM Results and Proceedings/Avantel_Limited_AGM_Transcript_30_05_2024.pdf"
                    }
                ]
            },
            {
                name: "Postal Ballot Notice",
                menu3: [],
                years: [
                    {
                        name: "Notice of Postal Ballot 09.10.2023",
                        pdf: "InvestorsNewData/Financial Information/29. Postal Ballot Notice/notice_of_postal_ballot_09_10_2023.pdf"
                    },
                    {
                        name: "Postal Ballot Notice 23.06.2023",
                        pdf: "InvestorsNewData/Financial Information/29. Postal Ballot Notice/POSTAL BALLOT NOTICE 23.06.2023.pdf"
                    },
                    {
                        name: "Postal Ballot Notice 06.04.2017",
                        pdf: "InvestorsNewData/Financial Information/29. Postal Ballot Notice/Postal Ballot Notice 06.04.2017.pdf"
                    },
                    {
                        name: "Postal Ballot Notice 15.12.2014",
                        pdf: "InvestorsNewData/Financial Information/29. Postal Ballot Notice/Postal Ballot Notice 15.12.2014.pdf"
                    }
                ]
            },
            {
                name: "Scrutinizers Report on Voting Results",
                menu3: [],
                years: [
                   
                    {
                        name: "Scrutineer Report AGM 2015",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/Scrutineer Report AGM 2015.pdf"
                    },
                    {
                        name: "Scrutinizer Report Evoting AGM 2015",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/Scrutinizer Report Evoting AGM 2015.pdf"
                    },
                    {
                        name: "Scrutinizer Report Poll AGM 2015",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/Scrutinizer Report Poll AGM 2015.pdf"
                    },
                    {
                        name: "scrutinizers_report agm 2020",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/scrutinizers_report agm 2020.pdf"
                    },
                    {
                        name: "voting results 2016",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/voting results 2016.pdf"
                    },
                    {
                        name: "proceedings at agm 2016",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/proceedings at agm 2016.pdf"
                    },
                    {
                        name: "voting_results 26 06 20",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/voting_results 26 06 20.pdf"
                    },
                    {
                        name: "Scrutinizer Report & Voting Results 23-06-2023",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/Scrutinizer Report & Voting Results 23.06.2023.pdf"
                    },
                    {
                        name: "POSTAL BALLOT - SCRUTINIZER REPORT AND VOTING RESULTS 31.07.2023",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/POSTAL-BALLOT-SCRUTINIZER-REPORT-AND-VOTING-RESULTS-31-07-2023.pdf"
                    },
                    {
                        name: "POSTAL BALLOT - SCRUTINIZER REPORT AND VOTING RESULTS 11.11.2023",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/POSTAL-BALLOT-SCRUTINIZER-REPORT-AND-VOTING-RESULTS-11-11-2023.pdf"
                    },
                    {
                        name: "SCRUTINIZER REPORT AND VOTING RESULTS 30.05.2024",
                        pdf: "InvestorsNewData/Financial Information/30. Scrutinizers Report on Voting Results/SCRUTINIZER_REPORT_AND_VOTING_RESULTS_30_05_2024.pdf"
                    },
                ]
            }
        ]
    }
]
export class Investors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "menu1",
            submenu2: "",
            submenu3: "",
            secondmenu: [],
            thirdmenu: [],
            years: [],
            showSubmenu2: true,
            showSubmenu3: false,
            showYears: false
        }
    }

    componentDidMount() {
        this.setState({
            secondmenu: menus[0].menu2
        })
    }

    fetchmenu2(menuName, view) {
        for (let i in menus) {
            if (menus[i].menu1 === menuName) {
                this.setState({
                    secondmenu: menus[i].menu2,
                    view: view,
                    showSubmenu2: true,
                    showSubmenu3: false,
                    showYears: false
                })
            }
        }
    }

    fetchmenu3(menuName) {
        for (let i in this.state.secondmenu) {
            if (this.state.secondmenu[i].name === menuName && this.state.secondmenu[i].menu3.length > 0) {
                this.setState({
                    submenu2: menuName,
                    submenu3: "",
                    thirdmenu: this.state.secondmenu[i].menu3,
                    showSubmenu2: false,
                    showSubmenu3: true,
                    showYears: false
                })
            } else if (this.state.secondmenu[i].name === menuName && this.state.secondmenu[i].menu3.length == 0 && this.state.secondmenu[i].years.length > 0) {
                this.setState({
                    submenu2: menuName,
                    submenu3: "",
                    years: this.state.secondmenu[i].years,
                    showSubmenu2: false,
                    showSubmenu3: false,
                    showYears: true
                })
            } else if (this.state.secondmenu[i].name === menuName) {
                this.setState({
                    submenu2: menuName,
                    submenu3: "",
                    thirdmenu: this.state.secondmenu[i].menu3,
                    showSubmenu2: false,
                    showSubmenu3: true,
                    showYears: false
                })
            }
        }
    }

    fetchYears(menuName) {
        for (let i in this.state.thirdmenu) {
            if (this.state.thirdmenu[i].name === menuName) {
                this.setState({
                    submenu3: menuName,
                    years: this.state.thirdmenu[i].years,
                    showSubmenu2: false,
                    showSubmenu3: false,
                    showYears: true
                })
            }
        }
    }

    render() {
        return (
            <div>
                <Navbar page="investors" />
                <main className="mainContent1">
                    <section className="investorsSection">
                        <div className="investorsSectionHeading">
                            <div className='investorsBanner'></div>
                        </div>
                        <div className='investorsTabs'>
                            <button className={`btn ${this.state.view === 'menu1' ? 'custom-primary1' : 'custom-outline-primary'} mb-2 `} onClick={() => this.fetchmenu2('Disclosure Under Regulation 46', 'menu1')}>Disclosure Under Regulation 46</button>
                            <button className={`btn ${this.state.view === 'menu2' ? 'custom-primary1' : 'custom-outline-primary'} mb-2 `} onClick={() => this.fetchmenu2('Disclosure Under Regulation 62', 'menu2')}>Disclosure Under Regulation 62</button>
                            <button className={`btn ${this.state.view === 'menu3' ? 'custom-primary1' : 'custom-outline-primary'} mb-2 `} onClick={() => this.fetchmenu2('Shareholders Information', 'menu3')}>Shareholders Information</button>
                            <button className={`btn ${this.state.view === 'menu4' ? 'custom-primary1' : 'custom-outline-primary'} mb-2 `} onClick={() => this.fetchmenu2('Financial Information', 'menu4')}>Financial Information</button>
                        </div>
                    </section>
                    <section className={`investorsTabSection ${this.state.showSubmenu2 ? 'd-block' : 'd-none'}`}>
                        <div className='row'>
                            {this.state.secondmenu.map((item, idx) => (
                                <div className='col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch investorCol'>
                                    <div className='card investorCard justify-content-center mb-4'>
                                        <a className="" onClick={() => this.fetchmenu3(item.name)}>
                                            <div className="card-body">
                                                <h5>{item.name}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                    <section className={`investorsTabSection ${this.state.showSubmenu3 ? 'd-block' : 'd-none'}`}>
                        <div className='row'>
                            <a className='submenuLink'>{this.state.submenu2}</a>
                            {this.state.thirdmenu.length > 0 ?
                                <>
                                    {this.state.thirdmenu.map((item, idx) => (
                                        <div className='col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch investorCol'>
                                            <div className='card investorCard justify-content-center mb-4'>
                                                <a className="" onClick={() => this.fetchYears(item.name)}>
                                                    <div className="card-body">
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                <p>No Data Found</p>
                            }
                        </div>
                    </section>
                    <section className={`investorsTabSection ${this.state.showYears ? 'd-block' : 'd-none'}`}>
                        <div className='row'>
                            <div className='d-flex'>
                                <a className={`submenuLink ${this.state.submenu3 != "" ? 'highlightLink' : ''}`} onClick={() => this.fetchmenu3(this.state.submenu2)}>{this.state.submenu2} </a>
                                {this.state.submenu3 != "" ? <a className='submenuLink'> - {this.state.submenu3}</a> : ''}
                            </div>
                            {this.state.years.length > 0 ?
                                <>
                                    {this.state.years.map((item, idx) => (
                                        <div className='col-lg-12 col-md-12 col-sm-12 d-flex align-items-stretch investorCol'>
                                            <div className='card pdfCard justify-content-center'>
                                                <a className="" href={item.pdf} target="_blank" rel='noopener noreferrer' >
                                                    <div className="card-body">
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </a>
                                            </div>
                                           

                                        </div>
                                    ))}
                                </>
                                :
                                <p>No Data Found</p>
                            }
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        )
    }
}

export default Investors